.AuthenticationAd {
  height: 100%;
  position: relative;
  width: 100%;

  > div {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    > img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }
}
