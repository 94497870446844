@import "../../../styles/abstracts";

.AuthenticationWebsitesNav {
  margin: 0 0 1rem 0;
  width: 100%;

  @include for-desktop {
    margin: 0;
    width: 50%;
  }

  > ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    margin: -0.5rem 0 0 -0.5rem;

    @include for-desktop {
      justify-content: left;
    }

    > li {
      margin: 0.5rem 0 0 0.5rem;

      > a {
        color: #979797;
        font-weight: bold;
        text-decoration: none;

        &::after {
          background: currentColor;
          content: "";
          display: block;
          height: 0.125rem;
          transition: width 0.25s ease-in-out;
          width: 0;
        }

        &[aria-current],
        &:focus,
        &:hover {
          color: #48accf;

          &::after {
            width: 100%;
          }
        }
      }
    }
  }
}
