@import "../../../styles/abstracts";

.AuthenticationHeader {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;

  @include for-desktop {
    flex-direction: row;
  }
}
