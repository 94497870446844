@import "../../styles/abstracts";

.AppendicesNav {
  margin: 0 auto;
  text-align: center;

  @include for-desktop() {
    margin: 0 0 0 auto;
    text-align: right;
  }

  > ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;

    @include for-desktop() {
      flex-direction: row;
    }

    > li {
      @include for-desktop() {
        &:not(:last-child) {
          &::after {
            content: "|";
            margin: 0 0.25rem;
          }
        }
      }

      > button {
        background: none;
        border: none;
        padding: 0;
        text-decoration: underline;

        &:focus,
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
