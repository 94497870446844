@import "./styles/abstracts";

.AuthenticationApp {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 0 1rem;

  @include for-desktop() {
    padding: 0 4rem;

    &::after,
    &::before {
      background: #d4f2f9;
      content: "";
      display: block;
      position: absolute;
      z-index: -1;
    }

    &::after {
      bottom: 0;
      height: 50%;
      right: 0;
      width: 75%;
    }

    &::before {
      height: 17.5rem;
      left: 0;
      top: 0;
      width: 35rem;
    }
  }

  &-ad {
    display: none;

    @include for-desktop() {
      display: block;
      flex-grow: 1;
    }
  }

  &-main {
    background: #fff;
    border-radius: 0.375rem;
    box-shadow: 0 0.5rem 0.5rem rgba(#979797, 0.25);
    display: flex;
    flex-grow: 1;
  }

  &-routes {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    width: 100%;

    @include for-desktop() {
      padding: 3rem;
      width: 36rem;
    }

    > img {
      margin: auto auto 0 auto;
    }
  }
}
