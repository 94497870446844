@import "../../../styles/abstracts";

.AuthenticationNavBar {
  align-items: center;
  display: flex;
  width: 100%;

  @include for-desktop {
    width: 50%;
  }

  &-logo {
    @include for-desktop {
      margin-left: -84px;
    }

    > a {
      &:focus,
      &:hover {
        opacity: 0.75;
      }
    }
  }
}
