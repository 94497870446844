.LoadingContainer {
  align-items: center;
  display: flex;
  flex-grow: 1;
  height: 100%;
  justify-content: center;
  width: 100%;

  .Spinner {
    font-size: 2.5em;
  }
}
